import * as React from 'react';
import * as styles from './tiles.module.scss';
import classnames from 'classnames';
import tilesContent from './tilesContent';

import Tile from './Tile';
import { TileType } from './Tile/tileTypes';

interface Props {}
interface State {
  filterActive: boolean;
  acquiredTiles: TileType[];
  allTiles: TileType[];
  activeTile: number | null;
}

function shuffleArray(array: TileType[]) {
  for (let i = array.length - 1; i > 0; i = i - 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export default class Tiles extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const tiles = tilesContent();

    // For the acquired page, get elements that have an order
    const acquiredUnordered = tiles.filter(tile => tile.acquiredIndex !== null);
    const acquiredAndOrdered = acquiredUnordered.sort(
      // @ts-ignore null's removed in previous line but result should be cast.
      (a, b) => a.acquiredIndex - b.acquiredIndex,
    );
    // Get remaining objects and randomize them.
    const acquiredLeftOvers = tiles.filter(
      tile => !acquiredAndOrdered.includes(tile) && (tile.acquired || tile.ipo),
    );
    shuffleArray(acquiredLeftOvers);
    // Combine the two arrays
    const acquiredTiles = acquiredAndOrdered.concat(acquiredLeftOvers);

    // For the all page, get elements that have an order
    const allUnordered = tiles.filter(tile => tile.allIndex !== null);
    const allAndOrdered = allUnordered.sort(
      // @ts-ignore null's removed in previous line but result should be cast.
      (a, b) => a.allIndex - b.allIndex,
    );
    // Get remaining objects and randomize them.
    const allLeftOvers = tiles.filter(tile => !allAndOrdered.includes(tile));
    shuffleArray(allLeftOvers);
    // Combine the two arrays
    const allTiles = allAndOrdered.concat(allLeftOvers);

    this.state = {
      acquiredTiles,
      allTiles,
      filterActive: false,
      activeTile: null,
    };
  }

  handleClicked = (index: number) => {
    if (this.state.activeTile === index) {
      return this.setState({
        activeTile: null,
      });
    }
    this.setState({
      activeTile: index,
    });
  };

  render() {
    const activeTiles = this.state.filterActive
      ? this.state.acquiredTiles
      : this.state.allTiles;
    return (
      <div className={styles.container}>
        <div className={styles.filterBar}>
          <div
            className={classnames(
              styles.filter,
              this.state.filterActive ? '' : styles.active,
            )}
            onClick={() => this.setState({ filterActive: false })}
          >
            All
          </div>
          <div
            className={classnames(
              styles.filter,
              this.state.filterActive ? styles.active : '',
            )}
            onClick={() => this.setState({ filterActive: true })}
          >
            Public / Acquired
          </div>
        </div>
        <div className={styles.tilesContainer}>
          {activeTiles.map((tile: TileType, index: number) => {
            if (this.state.filterActive && (!tile.acquired && !tile.ipo)) {
              return null;
            }
            return (
              <div
                className={styles.tileContainer}
                onClick={() => this.handleClicked(index)}
                key={tile.title}
              >
                <Tile
                  index={index}
                  activeTile={this.state.activeTile}
                  tile={tile}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
