import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const imageQuery = () =>
  useStaticQuery(graphql`
    query {
      glider_w: file(relativePath: { eq: "companyLogos_dark/glider_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      caramel_w: file(relativePath: { eq: "companyLogos_dark/caramel_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      accretiveCommerce_w: file(
        relativePath: { eq: "companyLogos_dark/accretivecommerce_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      bioSight_w: file(
        relativePath: { eq: "companyLogos_dark/biosight_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      c3ai_w: file(relativePath: { eq: "companyLogos_dark/c3ai_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      chegg_w: file(relativePath: { eq: "companyLogos_dark/chegg_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      cloudCruiser_w: file(
        relativePath: { eq: "companyLogos_dark/cloudcruiser_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      cramster_w: file(
        relativePath: { eq: "companyLogos_dark/cramster_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      extensity_w: file(
        relativePath: { eq: "companyLogos_dark/extensity_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      fandango_w: file(
        relativePath: { eq: "companyLogos_dark/fandango_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      genesys_w: file(relativePath: { eq: "companyLogos_dark/genesys_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      globetouch_w: file(
        relativePath: { eq: "companyLogos_dark/globetouch_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      gtnexus_w: file(relativePath: { eq: "companyLogos_dark/gtnexus_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      houzz_w: file(relativePath: { eq: "companyLogos_dark/houzz_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      jitterbit_w: file(
        relativePath: { eq: "companyLogos_dark/jitterbit_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      kiverdi_w: file(relativePath: { eq: "companyLogos_dark/kiverdi_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      lifelink_w: file(
        relativePath: { eq: "companyLogos_dark/lifelink_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      loopcommerce_w: file(
        relativePath: { eq: "companyLogos_dark/loopcommerce_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      mfino_w: file(relativePath: { eq: "companyLogos_dark/mfino_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      mimik_w: file(relativePath: { eq: "companyLogos_dark/mimik_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      mobileum_w: file(
        relativePath: { eq: "companyLogos_dark/mobileum_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      neuroptics_w: file(
        relativePath: { eq: "companyLogos_dark/neuroptics_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      omsignal_w: file(
        relativePath: { eq: "companyLogos_dark/omsignal_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      scopus_w: file(relativePath: { eq: "companyLogos_dark/scopus_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      snapwiz_w: file(relativePath: { eq: "companyLogos_dark/snapwiz_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      vmware_w: file(relativePath: { eq: "companyLogos_dark/vmware_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      vxtream_w: file(relativePath: { eq: "companyLogos_dark/vxtream_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      winmore_w: file(relativePath: { eq: "companyLogos_dark/winmore_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      younow_w: file(relativePath: { eq: "companyLogos_dark/younow_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      addepar_w: file(relativePath: { eq: "companyLogos_dark/addepar_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      AgentIQ_w: file(relativePath: { eq: "companyLogos_dark/AgentIQ_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      Colorifix_w: file(
        relativePath: { eq: "companyLogos_dark/Colorifix_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      CommerceSciences_w: file(
        relativePath: { eq: "companyLogos_dark/CommerceSciences_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      DudaMobile_w: file(
        relativePath: { eq: "companyLogos_dark/DudaMobile_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      Equitant_w: file(
        relativePath: { eq: "companyLogos_dark/Equitant_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      firepond_w: file(
        relativePath: { eq: "companyLogos_dark/firepond_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      infolinks_w: file(
        relativePath: { eq: "companyLogos_dark/infolinks_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      innoviztech_w: file(
        relativePath: { eq: "companyLogos_dark/innoviztech_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      lanetix_w: file(relativePath: { eq: "companyLogos_dark/lanetix_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      RedKix_w: file(relativePath: { eq: "companyLogos_dark/RedKix_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      Sqribe_w: file(relativePath: { eq: "companyLogos_dark/Sqribe_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      Techsee_w: file(relativePath: { eq: "companyLogos_dark/Techsee_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      Tipalti_w: file(relativePath: { eq: "companyLogos_dark/Tipalti_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      Tripactions_w: file(
        relativePath: { eq: "companyLogos_dark/Tripactions_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      vFunction_w: file(
        relativePath: { eq: "companyLogos_dark/vFunction_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      Webraska_w: file(
        relativePath: { eq: "companyLogos_dark/Webraska_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      Wibiya_w: file(relativePath: { eq: "companyLogos_dark/Wibiya_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      Tinctorium_w: file(
        relativePath: { eq: "companyLogos_dark/tinctorium_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      Octane_w: file(relativePath: { eq: "companyLogos_dark/octane_w.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      Homelight_w: file(
        relativePath: { eq: "companyLogos_dark/homelight_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      AirProtein_w: file(
        relativePath: { eq: "companyLogos_dark/airprotein_w.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      addepar_b: file(relativePath: { eq: "companyLogos_dark/addepar_b.png" }) {
        childImageSharp {
          fixed(width: 106) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      AgentIQ_b: file(relativePath: { eq: "companyLogos_dark/AgentIQ_b.png" }) {
        childImageSharp {
          fixed(width: 76) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      Colorifix_b: file(
        relativePath: { eq: "companyLogos_dark/Colorifix_b.png" }
      ) {
        childImageSharp {
          fixed(width: 68) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      CommerceSciences_b: file(
        relativePath: { eq: "companyLogos_dark/CommerceSciences_b.png" }
      ) {
        childImageSharp {
          fixed(width: 82) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      DudaMobile_b: file(
        relativePath: { eq: "companyLogos_dark/DudaMobile_b.png" }
      ) {
        childImageSharp {
          fixed(width: 48) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      Equitant_b: file(
        relativePath: { eq: "companyLogos_dark/Equitant_b.png" }
      ) {
        childImageSharp {
          fixed(width: 64) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      firepond_b: file(
        relativePath: { eq: "companyLogos_dark/firepond_b.png" }
      ) {
        childImageSharp {
          fixed(width: 33) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      infolinks_b: file(
        relativePath: { eq: "companyLogos_dark/infolinks_b.png" }
      ) {
        childImageSharp {
          fixed(width: 91) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      innoviztech_b: file(
        relativePath: { eq: "companyLogos_dark/innoviztech_b.png" }
      ) {
        childImageSharp {
          fixed(width: 66) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      lanetix_b: file(relativePath: { eq: "companyLogos_dark/lanetix_b.png" }) {
        childImageSharp {
          fixed(width: 66) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      RedKix_b: file(relativePath: { eq: "companyLogos_dark/RedKix_b.png" }) {
        childImageSharp {
          fixed(width: 94) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      Sqribe_b: file(relativePath: { eq: "companyLogos_dark/Sqribe_b.png" }) {
        childImageSharp {
          fixed(width: 90) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      Techsee_b: file(relativePath: { eq: "companyLogos_dark/Techsee_b.png" }) {
        childImageSharp {
          fixed(width: 88) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      Tipalti_b: file(relativePath: { eq: "companyLogos_dark/Tipalti_b.png" }) {
        childImageSharp {
          fixed(width: 47) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      Tripactions_b: file(
        relativePath: { eq: "companyLogos_dark/Tripactions_b.png" }
      ) {
        childImageSharp {
          fixed(width: 101) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      vFunction_b: file(
        relativePath: { eq: "companyLogos_dark/vFunction_b.png" }
      ) {
        childImageSharp {
          fixed(width: 71) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      Webraska_b: file(
        relativePath: { eq: "companyLogos_dark/Webraska_b.png" }
      ) {
        childImageSharp {
          fixed(width: 171) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      Wibiya_b: file(relativePath: { eq: "companyLogos_dark/Wibiya_b.png" }) {
        childImageSharp {
          fixed(width: 58) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      glider_b: file(relativePath: { eq: "companyLogos_dark/glider_b.png" }) {
        childImageSharp {
          fixed(width: 68) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      caramel_b: file(relativePath: { eq: "companyLogos_dark/caramel_b.png" }) {
        childImageSharp {
          fixed(width: 68) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      accretiveCommerce_b: file(
        relativePath: { eq: "companyLogos_dark/accretivecommerce_b.png" }
      ) {
        childImageSharp {
          fixed(width: 68) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      bioSight_b: file(
        relativePath: { eq: "companyLogos_dark/biosight_b.png" }
      ) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      c3ai_b: file(relativePath: { eq: "companyLogos_dark/c3ai_b.png" }) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      chegg_b: file(relativePath: { eq: "companyLogos_dark/chegg_b.png" }) {
        childImageSharp {
          fixed(width: 94) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      cloudCruiser_b: file(
        relativePath: { eq: "companyLogos_dark/cloudcruiser_b.png" }
      ) {
        childImageSharp {
          fixed(width: 103) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      cramster_b: file(
        relativePath: { eq: "companyLogos_dark/cramster_b.png" }
      ) {
        childImageSharp {
          fixed(width: 107) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      extensity_b: file(
        relativePath: { eq: "companyLogos_dark/extensity_b.png" }
      ) {
        childImageSharp {
          fixed(width: 84) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      fandango_b: file(
        relativePath: { eq: "companyLogos_dark/fandango_b.png" }
      ) {
        childImageSharp {
          fixed(width: 122) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      genesys_b: file(relativePath: { eq: "companyLogos_dark/genesys_b.png" }) {
        childImageSharp {
          fixed(width: 99) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      globetouch_b: file(
        relativePath: { eq: "companyLogos_dark/globetouch_b.png" }
      ) {
        childImageSharp {
          fixed(width: 116) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      gtnexus_b: file(relativePath: { eq: "companyLogos_dark/gtnexus_b.png" }) {
        childImageSharp {
          fixed(width: 129) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      houzz_b: file(relativePath: { eq: "companyLogos_dark/houzz_b.png" }) {
        childImageSharp {
          fixed(width: 99) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      jitterbit_b: file(
        relativePath: { eq: "companyLogos_dark/jitterbit_b.png" }
      ) {
        childImageSharp {
          fixed(width: 108) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      kiverdi_b: file(relativePath: { eq: "companyLogos_dark/kiverdi_b.png" }) {
        childImageSharp {
          fixed(width: 106) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      lifelink_b: file(
        relativePath: { eq: "companyLogos_dark/lifelink_b.png" }
      ) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      loopcommerce_b: file(
        relativePath: { eq: "companyLogos_dark/loopcommerce_b.png" }
      ) {
        childImageSharp {
          fixed(width: 85) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      mfino_b: file(relativePath: { eq: "companyLogos_dark/mfino_b.png" }) {
        childImageSharp {
          fixed(width: 87) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      mimik_b: file(relativePath: { eq: "companyLogos_dark/mimik_b.png" }) {
        childImageSharp {
          fixed(width: 68) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      mobileum_b: file(
        relativePath: { eq: "companyLogos_dark/mobileum_b.png" }
      ) {
        childImageSharp {
          fixed(width: 94) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      neuroptics_b: file(
        relativePath: { eq: "companyLogos_dark/neuroptics_b.png" }
      ) {
        childImageSharp {
          fixed(width: 114) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      omsignal_b: file(
        relativePath: { eq: "companyLogos_dark/omsignal_b.png" }
      ) {
        childImageSharp {
          fixed(width: 109) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      scopus_b: file(relativePath: { eq: "companyLogos_dark/scopus_b.png" }) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      snapwiz_b: file(relativePath: { eq: "companyLogos_dark/snapwiz_b.png" }) {
        childImageSharp {
          fixed(width: 118) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      vmware_b: file(relativePath: { eq: "companyLogos_dark/vmware_b.png" }) {
        childImageSharp {
          fixed(width: 122) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      vxtream_b: file(relativePath: { eq: "companyLogos_dark/vxtream_b.png" }) {
        childImageSharp {
          fixed(width: 95) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      winmore_b: file(relativePath: { eq: "companyLogos_dark/winmore_b.png" }) {
        childImageSharp {
          fixed(width: 104) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      younow_b: file(relativePath: { eq: "companyLogos_dark/younow_b.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      Tinctorium_b: file(
        relativePath: { eq: "companyLogos_dark/tinctorium_b.png" }
      ) {
        childImageSharp {
          fixed(width: 116) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      Octane_b: file(relativePath: { eq: "companyLogos_dark/octane_b.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      AirProtein_b: file(
        relativePath: { eq: "companyLogos_dark/airprotein_b.png" }
      ) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      Homelight_b: file(
        relativePath: { eq: "companyLogos_dark/homelight_b.png" }
      ) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `);

export const Glider_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.glider_w.childImageSharp.fluid} />;
};
export const Caramel_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.caramel_w.childImageSharp.fluid} />;
};
export const AccretiveCommerce_w = () => {
  const data = imageQuery();
  return (
    <Img
      loading="auto"
      fluid={data.accretiveCommerce_w.childImageSharp.fluid}
    />
  );
};
export const BioSight_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.bioSight_w.childImageSharp.fluid} />;
};
export const C3ai_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.c3ai_w.childImageSharp.fluid} />;
};
export const Chegg_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.chegg_w.childImageSharp.fluid} />;
};
export const CloudCruiser_w = () => {
  const data = imageQuery();
  return (
    <Img loading="auto" fluid={data.cloudCruiser_w.childImageSharp.fluid} />
  );
};
export const Cramster_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.cramster_w.childImageSharp.fluid} />;
};
export const Extensity_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.extensity_w.childImageSharp.fluid} />;
};
export const Fandango_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.fandango_w.childImageSharp.fluid} />;
};
export const Genesys_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.genesys_w.childImageSharp.fluid} />;
};
export const Globetouch_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.globetouch_w.childImageSharp.fluid} />;
};
export const Gtnexus_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.gtnexus_w.childImageSharp.fluid} />;
};
export const Houzz_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.houzz_w.childImageSharp.fluid} />;
};
export const Jitterbit_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.jitterbit_w.childImageSharp.fluid} />;
};
export const Kiverdi_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.kiverdi_w.childImageSharp.fluid} />;
};
export const Lifelink_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.lifelink_w.childImageSharp.fluid} />;
};
export const Loopcommerce_w = () => {
  const data = imageQuery();
  return (
    <Img loading="auto" fluid={data.loopcommerce_w.childImageSharp.fluid} />
  );
};
export const Mfino_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.mfino_w.childImageSharp.fluid} />;
};
export const Mimik_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.mimik_w.childImageSharp.fluid} />;
};
export const Mobileum_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.mobileum_w.childImageSharp.fluid} />;
};
export const Neuroptics_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.neuroptics_w.childImageSharp.fluid} />;
};
export const Omsignal_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.omsignal_w.childImageSharp.fluid} />;
};
export const Scopus_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.scopus_w.childImageSharp.fluid} />;
};
export const Snapwiz_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.snapwiz_w.childImageSharp.fluid} />;
};
export const Vmware_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.vmware_w.childImageSharp.fluid} />;
};
export const Vxtream_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.vxtream_w.childImageSharp.fluid} />;
};
export const Winmore_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.winmore_w.childImageSharp.fluid} />;
};
export const Younow_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.younow_w.childImageSharp.fluid} />;
};
export const addepar_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.addepar_w.childImageSharp.fluid} />;
};
export const AgentIQ_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.AgentIQ_w.childImageSharp.fluid} />;
};
export const Colorifix_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.Colorifix_w.childImageSharp.fluid} />;
};
export const CommerceSciences_w = () => {
  const data = imageQuery();
  return (
    <Img loading="auto" fluid={data.CommerceSciences_w.childImageSharp.fluid} />
  );
};
export const DudaMobile_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.DudaMobile_w.childImageSharp.fluid} />;
};
export const Equitant_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.Equitant_w.childImageSharp.fluid} />;
};
export const firepond_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.firepond_w.childImageSharp.fluid} />;
};
export const infolinks_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.infolinks_w.childImageSharp.fluid} />;
};
export const innoviztech_w = () => {
  const data = imageQuery();
  return (
    <Img loading="auto" fluid={data.innoviztech_w.childImageSharp.fluid} />
  );
};
export const lanetix_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.lanetix_w.childImageSharp.fluid} />;
};
export const RedKix_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.RedKix_w.childImageSharp.fluid} />;
};
export const Sqribe_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.Sqribe_w.childImageSharp.fluid} />;
};
export const Techsee_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.Techsee_w.childImageSharp.fluid} />;
};
export const Tipalti_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.Tipalti_w.childImageSharp.fluid} />;
};
export const Tripactions_w = () => {
  const data = imageQuery();
  return (
    <Img loading="auto" fluid={data.Tripactions_w.childImageSharp.fluid} />
  );
};
export const vFunction_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.vFunction_w.childImageSharp.fluid} />;
};
export const Webraska_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.Webraska_w.childImageSharp.fluid} />;
};
export const Wibiya_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.Wibiya_w.childImageSharp.fluid} />;
};
export const Tinctorium_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.Tinctorium_w.childImageSharp.fluid} />;
};
export const Octane_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.Octane_w.childImageSharp.fluid} />;
};
export const Homelight_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.Homelight_w.childImageSharp.fluid} />;
};
export const AirProtein_w = () => {
  const data = imageQuery();
  return <Img loading="auto" fluid={data.AirProtein_w.childImageSharp.fluid} />;
};
export const Glider_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.glider_b.childImageSharp.fixed}
    />
  );
};
export const Caramel_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.caramel_b.childImageSharp.fixed}
    />
  );
};
export const AccretiveCommerce_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.accretiveCommerce_b.childImageSharp.fixed}
    />
  );
};
export const BioSight_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.bioSight_b.childImageSharp.fixed}
    />
  );
};
export const C3ai_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.c3ai_b.childImageSharp.fixed}
    />
  );
};
export const Chegg_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.chegg_b.childImageSharp.fixed}
    />
  );
};
export const CloudCruiser_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.cloudCruiser_b.childImageSharp.fixed}
    />
  );
};
export const Cramster_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.cramster_b.childImageSharp.fixed}
    />
  );
};
export const Extensity_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.extensity_b.childImageSharp.fixed}
    />
  );
};
export const Fandango_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.fandango_b.childImageSharp.fixed}
    />
  );
};
export const Genesys_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.genesys_b.childImageSharp.fixed}
    />
  );
};
export const Globetouch_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.globetouch_b.childImageSharp.fixed}
    />
  );
};
export const Gtnexus_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.gtnexus_b.childImageSharp.fixed}
    />
  );
};
export const Houzz_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.houzz_b.childImageSharp.fixed}
    />
  );
};
export const Jitterbit_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.jitterbit_b.childImageSharp.fixed}
    />
  );
};
export const Kiverdi_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.kiverdi_b.childImageSharp.fixed}
    />
  );
};
export const Lifelink_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.lifelink_b.childImageSharp.fixed}
    />
  );
};
export const Loopcommerce_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.loopcommerce_b.childImageSharp.fixed}
    />
  );
};
export const Mfino_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.mfino_b.childImageSharp.fixed}
    />
  );
};
export const Mimik_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.mimik_b.childImageSharp.fixed}
    />
  );
};
export const Mobileum_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.mobileum_b.childImageSharp.fixed}
    />
  );
};
export const Neuroptics_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.neuroptics_b.childImageSharp.fixed}
    />
  );
};
export const Omsignal_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.omsignal_b.childImageSharp.fixed}
    />
  );
};
export const Scopus_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.scopus_b.childImageSharp.fixed}
    />
  );
};
export const Snapwiz_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.snapwiz_b.childImageSharp.fixed}
    />
  );
};
export const Vmware_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.vmware_b.childImageSharp.fixed}
    />
  );
};
export const Vxtream_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.vxtream_b.childImageSharp.fixed}
    />
  );
};
export const Winmore_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.winmore_b.childImageSharp.fixed}
    />
  );
};
export const Younow_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.younow_b.childImageSharp.fixed}
    />
  );
};
export const addepar_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.addepar_b.childImageSharp.fixed}
    />
  );
};
export const AgentIQ_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.AgentIQ_b.childImageSharp.fixed}
    />
  );
};
export const Colorifix_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.Colorifix_b.childImageSharp.fixed}
    />
  );
};
export const CommerceSciences_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.CommerceSciences_b.childImageSharp.fixed}
    />
  );
};
export const DudaMobile_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.DudaMobile_b.childImageSharp.fixed}
    />
  );
};
export const Equitant_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.Equitant_b.childImageSharp.fixed}
    />
  );
};
export const firepond_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.firepond_b.childImageSharp.fixed}
    />
  );
};
export const infolinks_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.infolinks_b.childImageSharp.fixed}
    />
  );
};
export const innoviztech_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.innoviztech_b.childImageSharp.fixed}
    />
  );
};
export const lanetix_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.lanetix_b.childImageSharp.fixed}
    />
  );
};
export const RedKix_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.RedKix_b.childImageSharp.fixed}
    />
  );
};
export const Sqribe_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.Sqribe_b.childImageSharp.fixed}
    />
  );
};
export const Techsee_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.Techsee_b.childImageSharp.fixed}
    />
  );
};
export const Tipalti_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.Tipalti_b.childImageSharp.fixed}
    />
  );
};
export const Tripactions_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.Tripactions_b.childImageSharp.fixed}
    />
  );
};
export const vFunction_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.vFunction_b.childImageSharp.fixed}
    />
  );
};
export const Webraska_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.Webraska_b.childImageSharp.fixed}
    />
  );
};
export const Wibiya_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.Wibiya_b.childImageSharp.fixed}
    />
  );
};
export const Tinctorium_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.Tinctorium_b.childImageSharp.fixed}
    />
  );
};
export const Octane_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.Octane_b.childImageSharp.fixed}
    />
  );
};
export const Homelight_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.Homelight_b.childImageSharp.fixed}
    />
  );
};
export const AirProtein_b = () => {
  const data = imageQuery();
  return (
    <Img
      fadeIn={false}
      imgStyle={{ objectFit: 'contain' }}
      fixed={data.AirProtein_b.childImageSharp.fixed}
    />
  );
};
