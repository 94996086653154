import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import BasicPage from '../templates/BasicPage';
import Title from '../components/shared/Title';
import Tiles from '../components/companies/Tiles';

const topTitle = `Companies`;
const bylines = [
  `We collaborate with passionate, creative, daring founders who see hidden opportunities in the world around us.`,
];

const CompaniesPage = () => (
  <Layout>
    <SEO pageTitle="Companies" description="" />
    <BasicPage light={true}>
      <Title title={topTitle} bylines={bylines} light={true} />
      <Tiles />
    </BasicPage>
  </Layout>
);

export default CompaniesPage;
