import {
  Glider_w,
  Caramel_w,
  AccretiveCommerce_w,
  BioSight_w,
  C3ai_w,
  Chegg_w,
  CloudCruiser_w,
  Cramster_w,
  Extensity_w,
  Fandango_w,
  Genesys_w,
  Globetouch_w,
  Gtnexus_w,
  Houzz_w,
  Jitterbit_w,
  Kiverdi_w,
  Lifelink_w,
  Loopcommerce_w,
  Mfino_w,
  Mimik_w,
  Mobileum_w,
  Neuroptics_w,
  Omsignal_w,
  Scopus_w,
  Snapwiz_w,
  Vmware_w,
  Vxtream_w,
  Winmore_w,
  Younow_w,
  Tinctorium_b,
  Octane_b,
  Homelight_b,
  AirProtein_b,
  Glider_b,
  Caramel_b,
  AccretiveCommerce_b,
  BioSight_b,
  C3ai_b,
  Chegg_b,
  CloudCruiser_b,
  Cramster_b,
  Extensity_b,
  Fandango_b,
  Genesys_b,
  Globetouch_b,
  Gtnexus_b,
  Houzz_b,
  Jitterbit_b,
  Kiverdi_b,
  Lifelink_b,
  Loopcommerce_b,
  Mfino_b,
  Mimik_b,
  Mobileum_b,
  Neuroptics_b,
  Omsignal_b,
  Scopus_b,
  Snapwiz_b,
  Vmware_b,
  Vxtream_b,
  Winmore_b,
  Younow_b,
  addepar_b,
  AgentIQ_b,
  Colorifix_b,
  CommerceSciences_b,
  DudaMobile_b,
  Equitant_b,
  firepond_b,
  infolinks_b,
  innoviztech_b,
  lanetix_b,
  RedKix_b,
  Sqribe_b,
  Techsee_b,
  Tipalti_b,
  Tripactions_b,
  vFunction_b,
  Webraska_b,
  Wibiya_b,
  addepar_w,
  AgentIQ_w,
  Colorifix_w,
  CommerceSciences_w,
  DudaMobile_w,
  Equitant_w,
  firepond_w,
  infolinks_w,
  innoviztech_w,
  lanetix_w,
  RedKix_w,
  Sqribe_w,
  Techsee_w,
  Tipalti_w,
  Tripactions_w,
  vFunction_w,
  Webraska_w,
  Wibiya_w,
  Tinctorium_w,
  Octane_w,
  Homelight_w,
  AirProtein_w,
} from './imageComponents/images';

export default () => [
  {
    LargeLogo: Glider_w,
    SmallLogo: Glider_b,
    title: `Glider`,
    location: null,
    description: `Glider is an AI powered talent quality platform helping Enterprises, MSPs and Recruitment Agencies build great teams with qualified candidates that precisely match their competency requirements.`,
    websitePath: `https://glider.ai/`,
    bgColor: `#0390DF`,
    acquired: null,
    ipo: null,
    acquiredIndex: null,
    allIndex: 5,
  },
  {
    LargeLogo: Caramel_w,
    SmallLogo: Caramel_b,
    title: `Caramel`,
    location: null,
    description: ``,
    websitePath: `https://www.drivecaramel.com/`,
    bgColor: `#172154`,
    acquired: null,
    ipo: null,
    acquiredIndex: null,
    allIndex: 6,
  },
  {
    LargeLogo: AccretiveCommerce_w,
    SmallLogo: AccretiveCommerce_b,
    title: `Accretive Commerce`,
    location: null,
    description: `Accretive Commerce is a e-commerce technology provider. It was acquired by GSI Commerce in 1995.`,
    websitePath: null,
    bgColor: `#032963`,
    acquired: 'GSI Commerce',
    ipo: null,
    acquiredIndex: null,
    allIndex: null,
  },
  {
    LargeLogo: addepar_w,
    SmallLogo: addepar_b,
    title: `Addepar`,
    location: 'Mountain View, California, USA',
    description: `Addepar is a provider of operational software for the financial services industry.`,
    websitePath: 'https://addepar.com/',
    bgColor: `#032963`,
    acquired: null,
    ipo: null,
    acquiredIndex: null,
    allIndex: null,
  },
  // {
  //   LargeLogo: AgentIQ_w,
  //   SmallLogo: AgentIQ_b,
  //   title: `AgentIQ`,
  //   location: 'San Francisco, California, USA',
  //   description: `AgentIQ is a customer engagement platform for the consumer financial services industry.`,
  //   websitePath: 'http://agentiq.com/',
  //   bgColor: `#032963`,
  //   acquired: null,
  //   ipo: null,
  //   acquiredIndex: null,
  //   allIndex: null,
  // },
  {
    LargeLogo: BioSight_w,
    SmallLogo: BioSight_b,
    title: `BioSight`,
    location: `Lod, Israel`,
    description: `BioSight develops chemotherapy pro-drugs that target cancer cells while significantly minimizing toxicity.`,
    websitePath: `https://www.biosight-pharma.com/`,
    bgColor: `#693f8c`,
    acquired: null,
    ipo: null,
    acquiredIndex: null,
    allIndex: null,
  },
  {
    LargeLogo: C3ai_w,
    SmallLogo: C3ai_b,
    title: `C3`,
    location: `Redwood City, California, USA`,
    description: `C3 Energy builds smart grid analytics software that harnesses the power of big data, social networking, machine learning, and cloud computing.`,
    websitePath: `https://c3.ai/`,
    bgColor: `#3392ff`,
    acquired: null,
    ipo: null,
    acquiredIndex: null,
    allIndex: null,
  },
  {
    LargeLogo: Chegg_w,
    SmallLogo: Chegg_b,
    title: `Chegg`,
    location: 'Santa Clara, California, USA',
    description: `Chegg is a provider of textbooks and other education resources. IPO 2013.`,
    websitePath: 'https://www.chegg.com/',
    bgColor: `#f27d00`,
    acquired: null,
    ipo: '2013',
    acquiredIndex: 2,
    allIndex: 5,
  },
  // {
  //   LargeLogo: CloudCruiser_w,
  //   SmallLogo: CloudCruiser_b,
  //   title: `Cloud Cruiser`,
  //   location: `San Jose, California, USA`,
  //   description: `Cloud Cruiser offers an innovative cloud financial management solution built from the ground up for the cloud economy.`,
  //   websitePath: `https://www.cloudcruiser.com/`,
  //   bgColor: `#233a9a`,
  //   acquired: null,
  //   ipo: null,
  //   acquiredIndex: null,
  //   allIndex: null,
  // },
  {
    LargeLogo: Colorifix_w,
    SmallLogo: Colorifix_b,
    title: `Colorifix`,
    location: ' Norwich, Norfolk, United Kingdom',
    description: `Colorifix uses synthetic biology to generate environmentally sustainable dyes for the fashion industry.`,
    websitePath: `http://www.colorifix.com/`,
    bgColor: `#233a9a`,
    acquired: null,
    ipo: null,
    acquiredIndex: null,
    allIndex: 3,
  },
  {
    LargeLogo: CommerceSciences_w,
    SmallLogo: CommerceSciences_b,
    title: `Commerce Sciences`,
    location: 'Palo Alto, California, USA',
    description: `Commerce Sciences offers a website personalization platform for online marketers to convert more customers. Acquired by Taboola in 2017.`,
    websitePath: `http://commercesciences.com/`,
    bgColor: `#233a9a`,
    acquired: 'Taboola',
    ipo: null,
    acquiredIndex: null,
    allIndex: null,
  },
  {
    LargeLogo: Cramster_w,
    SmallLogo: Cramster_b,
    title: `Cramster`,
    location: 'Pasadena, California, USA',
    description: `Cramster is an online homework help provider. It was acquired by Chegg in 2010.`,
    websitePath: 'http://www.cramster.com/',
    bgColor: `#f01614`,
    acquired: 'Chegg',
    ipo: null,
    acquiredIndex: 5,
    allIndex: null,
  },
  {
    LargeLogo: DudaMobile_w,
    SmallLogo: DudaMobile_b,
    title: `Duda Mobile`,
    location: 'Palo Alto, California, USA',
    description: `Duda is a web design platform.`,
    websitePath: 'https://www.duda.co/',
    bgColor: `#2f456a`,
    acquired: null,
    ipo: null,
    acquiredIndex: null,
    allIndex: null,
  },
  {
    LargeLogo: Extensity_w,
    SmallLogo: Extensity_b,
    title: `Extensify`,
    location: 'New York, New York, USA',
    description: `Extensity is an enterprise software company that provides financial and operational solutions. IPO 2000.`,
    websitePath: 'http://www.extensity.com/',
    bgColor: `#2f456a`,
    acquired: null,
    ipo: '2000',
    acquiredIndex: null,
    allIndex: null,
  },
  {
    LargeLogo: Equitant_w,
    SmallLogo: Equitant_b,
    title: `Equitant`,
    location: 'Dublin, Ireland',
    description: `Equitant is a provider of capital management solutions. It was acquired by IBM in 2005.`,
    websitePath: 'http://www.equitant.com/',
    bgColor: `#2f456a`,
    acquired: 'IBM',
    ipo: null,
    acquiredIndex: 4,
    allIndex: null,
  },
  {
    LargeLogo: Fandango_w,
    SmallLogo: Fandango_b,
    title: `Fandango`,
    location: 'Los Angeles, California, USA',
    description: `Fandango is a provider of ticketing and media information. It was acquired by Comcast in 2007.`,
    websitePath: 'http://www.fandango.com/',
    bgColor: `#3076c4`,
    acquired: 'Comcast',
    ipo: null,
    acquiredIndex: 0,
    allIndex: 0,
  },
  {
    LargeLogo: firepond_w,
    SmallLogo: firepond_b,
    title: `firepond`,
    location: 'Dallas, Texas, USA',
    description: `FirePond's software and services let companies merge their e-commerce selling, customer relationship management and channel management strategies on a single, Internet-based platform. IPO 2002.`,
    websitePath: 'http://www.firepond.com/',
    bgColor: `#3076c4`,
    acquired: null,
    ipo: `2002`,
    acquiredIndex: null,
    allIndex: null,
  },
  {
    LargeLogo: Genesys_w,
    SmallLogo: Genesys_b,
    title: `Genesys`,
    location: 'Daly City, California, USA',
    description: `Genesys is a customer service and call center technology provider. IPO 1997.`,
    websitePath: 'http://www.genesys.com/',
    bgColor: `#ff4e01`,
    acquired: null,
    ipo: '1997',
    acquiredIndex: null,
    allIndex: null,
  },
  {
    LargeLogo: Globetouch_w,
    SmallLogo: Globetouch_b,
    title: `GlobeTouch`,
    location: `Oakland, California, USA`,
    description: `GlobeTouch provides global connectivity services through an ecosystem of mobile operators, device manufacturers, enterprises, and mobile-OS companies.`,
    websitePath: `http://www.globetouch.com/`,
    bgColor: `#3392ff`,
    acquired: null,
    ipo: null,
    acquiredIndex: null,
    allIndex: null,
  },
  {
    LargeLogo: Gtnexus_w,
    SmallLogo: Gtnexus_b,
    title: `GT Nexus`,
    location: 'Oakland, California, USA',
    description: `GT Nexus is a cloud solution for the global trade and logistics. It was acquired by Infor in 2015.`,
    websitePath: 'http://www.gtnexus.com/',
    bgColor: `#d60000`,
    acquired: 'Infor',
    ipo: null,
    acquiredIndex: 3,
    allIndex: 7,
  },
  {
    LargeLogo: Houzz_w,
    SmallLogo: Houzz_b,
    title: `Houzz`,
    location: `San Francisco, California, USA`,
    description: `Houzz is a platform for home remodeling and design, bringing homeowners and home professionals together in a uniquely visual community.`,
    websitePath: `https://www.houzz.com/`,
    bgColor: `#48be00`,
    acquired: null,
    ipo: null,
    acquiredIndex: null,
    allIndex: 4,
  },
  {
    LargeLogo: infolinks_w,
    SmallLogo: infolinks_b,
    title: `Infolinks`,
    location: `Palo Alto, California, USA`,
    description: `Infolinks is a platform provider of smart advertisements.`,
    websitePath: `http://www.infolinks.com/`,
    bgColor: `#48be00`,
    acquired: null,
    ipo: null,
    acquiredIndex: null,
    allIndex: null,
  },
  {
    LargeLogo: innoviztech_w,
    SmallLogo: innoviztech_b,
    title: `Innoviz Technology`,
    location: `Tel Aviv, Tel Aviv, Israel`,
    description: `Innoviz develops high-performance, sold-state LiDAR sensors for safe automation.`,
    websitePath: `http://www.innoviz.tech/`,
    bgColor: `#48be00`,
    acquired: null,
    ipo: null,
    acquiredIndex: null,
    allIndex: null,
  },
  {
    LargeLogo: Jitterbit_w,
    SmallLogo: Jitterbit_b,
    title: `Jitterbit`,
    location: `Alameda, California, USA`,
    description: `Jitterbit offers simple-to-use integration software that overcomes the cost and complexity associated with connecting applications, data, and systems.`,
    websitePath: `https://www.jitterbit.com/`,
    bgColor: `#ff7c44`,
    acquired: null,
    ipo: null,
    acquiredIndex: null,
    allIndex: null,
  },
  {
    LargeLogo: Kiverdi_w,
    SmallLogo: Kiverdi_b,
    title: ` Kiverdi`,
    location: `Berkeley, California, USA`,
    description: `Kiverdi develops alternative fuels and bio-products. Their proprietary technology enables gasified waste to be transformed into viable products including chemicals and fuel.`,
    websitePath: `https://www.kiverdi.com/`,
    bgColor: `#f99d00`,
    acquired: null,
    ipo: null,
    acquiredIndex: null,
    allIndex: 6,
  },
  // {
  //   LargeLogo: lanetix_w,
  //   SmallLogo: lanetix_b,
  //   title: `Lanetix`,
  //   location: `San Francisco, California, USA`,
  //   description: `Lanetix’s (now Winmore) is an enterprise-grade workflow, collaboration, and CRM suite enables 3PLs and carriers to grow their revenue and profits.`,
  //   websitePath: `http://www.lanetix.com/`,
  //   bgColor: `#39aaad`,
  //   acquired: null,
  //   ipo: null,
  //   acquiredIndex: null,
  //   allIndex: null,
  // },
  {
    LargeLogo: Lifelink_w,
    SmallLogo: Lifelink_b,
    title: `LifeLink`,
    location: `San Francisco, California, USA`,
    description: `LifeLink is a San Francisco based team of doctors, technologists, and innovators dedicated to enabling remote access to high-quality medical care across borders and provider systems.`,
    websitePath: `https://www.lifelink.com/`,
    bgColor: `#39aaad`,
    acquired: null,
    ipo: null,
    acquiredIndex: null,
    allIndex: 1,
  },
  {
    LargeLogo: Loopcommerce_w,
    SmallLogo: Loopcommerce_b,
    title: `Loop Commerce`,
    location: `Menlo Park, California, USA`,
    description: `Loop is leading a paradigm shift in commerce with a unique gifting solution and technology that is being implemented by leading retailers.`,
    websitePath: `http://www.loopcommerce.com/`,
    bgColor: `#b81b19`,
    acquired: null,
    ipo: null,
    acquiredIndex: null,
    allIndex: null,
  },
  {
    LargeLogo: Mfino_w,
    SmallLogo: Mfino_b,
    title: `mFino`,
    location: `Sunnyvale, California, USA`,
    description: `mFino is an agile banking, MFS, and commerce platform that enables the delivery of a full spectrum of financial services to meet the current and future needs of financial institutions, mobile operators, distributors and retailers.`,
    websitePath: `https://www.mfino.com/`,
    bgColor: `#6085fb`,
    acquired: null,
    ipo: null,
    acquiredIndex: null,
    allIndex: null,
  },
  {
    LargeLogo: Mimik_w,
    SmallLogo: Mimik_b,
    title: `Mimik`,
    location: `Vancouver, Canada`,
    description: `Mimik has created an end-to-end software platform that enables multiple users to simultaneously access their TV content and all their media anytime, anywhere, and on any connected device.`,
    websitePath: `https://www.mimiktech.com/`,
    bgColor: `#f59200`,
    acquired: null,
    ipo: null,
    acquiredIndex: null,
    allIndex: null,
  },
  {
    LargeLogo: Mobileum_w,
    SmallLogo: Mobileum_b,
    title: `Mobileum`,
    location: `Santa Clara, California, USA`,
    description: `Mobileum employs its big data analytics and actions solutions to give telecom operators the insights they need to make the critical decisions.`,
    websitePath: `https://www.mobileum.com/`,
    bgColor: `#6e97a9`,
    acquired: `Audax Group`,
    ipo: null,
    acquiredIndex: null,
    allIndex: null,
  },
  // {
  //   LargeLogo: Neuroptics_w,
  //   SmallLogo: Neuroptics_b,
  //   title: `Neuroptics`,
  //   location: `Irvine, California, USA`,
  //   description: `Neuroptics makes devices which collect and process information from the human eye to facilitate medical decision-making and enable clinical research.`,
  //   websitePath: `https://www.neuroptics.com/`,
  //   bgColor: `#006fbd`,
  //   acquired: null,
  //   ipo: null,
  //   acquiredIndex: null,
  //   allIndex: null,
  // },
  // {
  //   LargeLogo: Omsignal_w,
  //   SmallLogo: Omsignal_b,
  //   title: `OMSignal`,
  //   location: `Montreal, Canada`,
  //   description: `OMSignal manufactures smart apparel that continuously tracks your biometrics and displays your data in real-time on your mobile phone.`,
  //   websitePath: `https://www.omsignal.com/`,
  //   bgColor: `#6e97a9`,
  //   acquired: null,
  //   ipo: null,
  //   acquiredIndex: null,
  //   allIndex: null,
  // },
  {
    LargeLogo: RedKix_w,
    SmallLogo: RedKix_b,
    title: `RedKix`,
    location: `San Mateo, California, USA`,
    description: `RedKix is a team collaboration tool. It was acquired by Facebook in 2018.`,
    websitePath: `http://www.redkix.com/`,
    bgColor: `#6e97a9`,
    acquired: 'Facebook',
    ipo: null,
    acquiredIndex: 7,
    allIndex: null,
  },
  {
    LargeLogo: Scopus_w,
    SmallLogo: Scopus_b,
    title: `Scopus`,
    location: 'Emeryville, California, USA',
    description: `Scopus was one of the first customer service and relationship management software platforms. Scopus was acquired by Siebel Systems in 1998.`,
    websitePath: 'https://www.scopus.com/',
    bgColor: `#ff8200`,
    acquired: null,
    ipo: '1995',
    acquiredIndex: null,
    allIndex: null,
  },
  {
    LargeLogo: Snapwiz_w,
    SmallLogo: Snapwiz_b,
    title: `Snapwiz`,
    location: `Fremont, California, USA`,
    description: `Snapwiz creates and delivers personalized learning experiences that leverage custom paths for individuals based on their strengths and weaknesses.`,
    websitePath: `https://snapwiz.com/`,
    bgColor: `#3a9aff`,
    acquired: null,
    ipo: null,
    acquiredIndex: null,
    allIndex: null,
  },
  {
    LargeLogo: Sqribe_w,
    SmallLogo: Sqribe_b,
    title: `Sqribe`,
    location: null,
    description: `Sqribe makes enterprise reporting software. Brio Technology acquired Sqribe in 1999.`,
    websitePath: null,
    bgColor: `#3a9aff`,
    acquired: 'Brio Technology',
    ipo: null,
    acquiredIndex: null,
    allIndex: null,
  },
  {
    LargeLogo: Techsee_w,
    SmallLogo: Techsee_b,
    title: `Techsee`,
    location: `Herzliya, Tel Aviv, Israel`,
    description: `TechSee is an intelligent visual assistance provider for customer engagement.`,
    websitePath: `http://www.techsee.me/`,
    bgColor: `#3a9aff`,
    acquired: null,
    ipo: null,
    acquiredIndex: null,
    allIndex: null,
  },
  {
    LargeLogo: Tipalti_w,
    SmallLogo: Tipalti_b,
    title: `Tipalti`,
    location: `San Mateo, California, USA`,
    description: `Tipalti is an end-to-end accounts payable software platform.`,
    websitePath: `https://tipalti.com/`,
    bgColor: `#3a9aff`,
    acquired: null,
    ipo: null,
    acquiredIndex: null,
    allIndex: null,
  },
  // {
  //   LargeLogo: Tripactions_w,
  //   SmallLogo: Tripactions_b,
  //   title: `TripActions`,
  //   location: `Palo Alto, California, USA`,
  //   description: `TripActions is a travel management platform for the business community.`,
  //   websitePath: `http://tripactions.com/`,
  //   bgColor: `#3a9aff`,
  //   acquired: null,
  //   ipo: null,
  //   acquiredIndex: null,
  //   allIndex: null,
  // },
  // {
  //   LargeLogo: vFunction_w,
  //   SmallLogo: vFunction_b,
  //   title: `vFunction`,
  //   location: `Menlo Park, California, USA`,
  //   description: `vFunction provides digital application modernisation solutions.`,
  //   websitePath: `http://www.vfunction.com/`,
  //   bgColor: `#3a9aff`,
  //   acquired: null,
  //   ipo: null,
  //   acquiredIndex: null,
  //   allIndex: null,
  // },
  {
    LargeLogo: Vmware_w,
    SmallLogo: Vmware_b,
    title: `vmware`,
    location: 'Palo Alto, California, USA',
    description: `VMWare is a cloud computing and virtualisation software provider. It was acquired by EMC in 2004.`,
    websitePath: 'https://www.vmware.com/',
    bgColor: `#696566`,
    acquired: 'EMC',
    ipo: null,
    acquiredIndex: 1,
    allIndex: 2,
  },
  {
    LargeLogo: Vxtream_w,
    SmallLogo: Vxtream_b,
    title: `VXtreme`,
    location: null,
    description: `VXtreme is a media development and streaming platform that was acquired by Microsoft in 1997.`,
    websitePath: null,
    bgColor: `#0096ff`,
    acquired: 'Microsoft',
    ipo: null,
    acquiredIndex: 6,
    allIndex: null,
  },
  {
    LargeLogo: Webraska_w,
    SmallLogo: Webraska_b,
    title: `Webraska`,
    location: `Poissy, Ile-de-France, France`,
    description: `Webraska is a provider of navigation, traffic, and geospatial solutions. It was acquired by Sanef in 2007.`,
    websitePath: null,
    bgColor: `#1dd88f`,
    acquired: 'Sanef',
    ipo: null,
    acquiredIndex: null,
    allIndex: null,
  },
  {
    LargeLogo: Wibiya_w,
    SmallLogo: Wibiya_b,
    title: `Wibiya`,
    location: `Foster City, California, USA`,
    description: `Wibiya was an online toolbar platform for content publishers. It was acquired by Conduit in 2011.`,
    websitePath: null,
    bgColor: `#1dd88f`,
    acquired: 'Conduit',
    ipo: null,
    acquiredIndex: null,
    allIndex: null,
  },
  // {
  //   LargeLogo: Winmore_w,
  //   SmallLogo: Winmore_b,
  //   title: `Winmore`,
  //   location: `San Francisco, California, USA`,
  //   description: `Bid and Tender Collaboration Software for Logistics Industry.`,
  //   websitePath: `http://www.winmore.app/`,
  //   bgColor: `#1dd88f`,
  //   acquired: null,
  //   ipo: null,
  //   acquiredIndex: null,
  //   allIndex: null,
  // },
  // {
  //   LargeLogo: Younow_w,
  //   SmallLogo: Younow_b,
  //   title: `YouNow`,
  //   location: `New York, New York, USA`,
  //   description: `YouNow is the best way to watch and broadcast interactive live stream videos, discover talented broadcasters, and chat live with people from around the world.`,
  //   websitePath: `https://www.younow.com/`,
  //   bgColor: `#5dc233`,
  //   acquired: null,
  //   ipo: null,
  //   acquiredIndex: null,
  //   allIndex: null,
  // },
  {
    LargeLogo: Tinctorium_w,
    SmallLogo: Tinctorium_b,
    title: `Tinctorium`,
    location: `San Francisco, California, USA`,
    description: `Tinctorium develops biotechnology to provide sustainable indigo dyeing solutions to the textile and fashion industry and reduce the use of hazardous chemicals in the denim supply chain.`,
    websitePath: `https://www.tinctoriumbio.com/`,
    bgColor: `#3c586b`,
    acquired: null,
    ipo: null,
    acquiredIndex: null,
    allIndex: null,
  },
  {
    LargeLogo: Octane_w,
    SmallLogo: Octane_b,
    title: `Octane Software`,
    location: `San Mateo, California, USA`,
    description: `Octane Software provides CRM applications and software. It was acquired by Epiphany in 2002.`,
    websitePath: null,
    bgColor: `#124288`,
    acquired: null,
    ipo: null,
    acquiredIndex: null,
    allIndex: null,
  },
  {
    LargeLogo: Homelight_w,
    SmallLogo: Homelight_b,
    title: `HomeLight`,
    location: `San Francisco, California, USA`,
    description: `Homelight is a real estate referral company empowering people to make smarter decisions while buying or selling their home.`,
    websitePath: `https://www.homelight.com/`,
    bgColor: `#46b6ff`,
    acquired: null,
    ipo: null,
    acquiredIndex: null,
    allIndex: null,
  },
  {
    LargeLogo: AirProtein_w,
    SmallLogo: AirProtein_b,
    title: `Air Protein`,
    location: `Berkeley, California, USA`,
    description: `Air Protein creates air-based meat from elements found in the air we breathe.`,
    websitePath: `https://www.airprotein.com/`,
    bgColor: `#1b81a6`,
    acquired: null,
    ipo: null,
    acquiredIndex: null,
    allIndex: null,
  },
];
