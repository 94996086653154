import * as React from 'react';
import * as styles from './tile.module.scss';
import classnames from 'classnames';
import { TileType } from './tileTypes';

interface Props {
  tile: TileType;
  index: number;
  activeTile: number | null;
}
interface State {
  bgColor: string;
  hovered: boolean;
}

export default class Tile extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      bgColor: 'rgba(0,0,0,0)',
      hovered: false,
    };
  }
  setBGColor = (color: string, hovered: boolean) => {
    this.setState({
      hovered,
      bgColor: color,
    });
  };

  render() {
    const LargeImage = this.props.tile.LargeLogo;
    const SmallImage = this.props.tile.SmallLogo;
    return (
      <div className={styles.tileContainer}>
        <div
          onMouseEnter={() => this.setBGColor(this.props.tile.bgColor, true)}
          onMouseLeave={() => this.setBGColor('rgba(0,0,0,0)', false)}
          style={{ backgroundColor: this.state.bgColor }}
          className={classnames(
            styles.front,
            this.props.index === this.props.activeTile
              ? styles.frontInactive
              : styles.frontActive,
          )}
        >
          <div className={styles.largeImageContainer}>
            <LargeImage />
            {this.props.tile.ipo && (
              <div className={styles.info}>IPO {this.props.tile.ipo}</div>
            )}
            {this.props.tile.acquired && (
              <div className={styles.info}>
                Acquired by {this.props.tile.acquired}
              </div>
            )}
          </div>
        </div>
        <div
          className={classnames(
            styles.back,
            this.props.index === this.props.activeTile
              ? styles.backActive
              : styles.backInactive,
          )}
        >
          <div className={styles.backContainer}>
            <div className={styles.smallImageContainer}>
              <SmallImage />
            </div>
            <h5 className={styles.title}>{this.props.tile.title}</h5>
            <p className={styles.location}>{this.props.tile.location}</p>
            <p className={styles.description}>{this.props.tile.description}</p>
          </div>
          {this.props.tile.websitePath ? (
            <a
              target="_blank"
              href={this.props.tile.websitePath}
              className={styles.website}
            >
              website
            </a>
          ) : null}
        </div>
      </div>
    );
  }
}
